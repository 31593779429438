::-webkit-scrollbar {
  /*  */
}

::-webkit-scrollbar-track {
  background: #fff;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #c2c7d0;
  /* color of the scroll thumb */
  border-radius: 10px;
  /* roundness of the scroll thumb */
  border: 1px solid #fff;
  /* creates padding around scroll thumb */
}
/* Estilo para o botão circular */
.floating-button {
  position: fixed; /* Fixa o botão na tela */
  bottom: 20px; /* Distância do rodapé */
  background-color: transparent; /* Cor de fundo */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
  display: flex;
  align-items: center;
  justify-content: center;
  right: 40px;
  z-index: 9999; /* Garante que fique sobre outros elementos */
  border: none; /* Remove bordas */
}
/* Adicionar uma transição suave */
.floating-button i + span {
  transition: transform 0.2s ease;
}

.floating-button:hover i {
  transform: translateX(
    -5px
  ); /* Move o ícone para a direita ao passar o mouse */
}
.floating-button:hover span {
  transform: translateX(
    5px
  ); /* Move o ícone para a direita ao passar o mouse */
}
.ContentBox {
  position: relative;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: "2rem";
}

.BackButton {
  border-radius: 1rem;
  position: fixed;
  bottom: 5rem;
}

.NextButton {
  border-radius: 1rem;
  position: fixed;
  bottom: 5rem;
  right: 3rem;
}

@media (max-width: 720px) {
  .BackButton {
    bottom: 8rem;
  }
  .NextButton {
    bottom: 8rem;
  }
}
.ImageItem {
  width: 7rem;
  height: 7rem;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  color: #343a40;
  border-radius: 1rem;
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.TrashIcon {
  position: absolute !important;
  top: 5px;
  right: 5px;
  font-size: 0.7rem;
  cursor: pointer;
}

.table_fixed_box {
  overflow: auto;
  max-height: 100vh;
}

.table_fixed_box thead th {
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
  background: #17a2b8;
  color: #fff;
  border: 2px #17a2b8;
}


.fixed-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 450px; 
  /* background-color: #343a40;  */
  background-color: #fff; 
  z-index: 1050; 
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
  transform: translateX(0); 
  transition: transform 1s ease-in-out;
}

.fixed-right.closed {
  transform: translateX(100%); 
}

.sidebar {
  height: 100%;
  overflow-y: auto;
}

@media (max-width:768px){
  .fixed-right {
    width: 100%;
  }
}